import React, { useContext, useEffect, useState } from "react"
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js"
import { Context } from "../contexts/StateStore"
import { navigate } from "gatsby"

const PayPal = () => {
  const [loading, setLoading] = useState(false)
  const [lineItems, setLineItems] = useState([])
  const [state] = useContext(Context)

  const initialOptions = {
    "client-id": process.env.GATSBY_PAYPAL_CLIENT_ID,
    currency: "USD",
    intent: "capture",
  }

  useEffect(() => {
    let cartItems = state.cart.map(item => {
      return {
        name: item.title,
        description: item.desc,
        unit_amount: {
          value: convertFromIntToPrice(item.price),
          currency_code: "USD",
        },
        quantity: item.qty,
      }
    })
    setLineItems(cartItems)
  }, [])

  const convertFromIntToPrice = price => {
    return (price / 100).toFixed(2)
  }

  return (
    <PayPalScriptProvider options={initialOptions}>
      <PayPalButtons
        style={{
          layout: "horizontal",
          shape: "pill",
          color: "silver",
          tagline: false,
          label: "paypal",
        }}
        createOrder={(data, actions) => {
          return actions.order.create({
            application_context: {
              brand_name: "TawAlap",
            },
            purchase_units: [
              {
                amount: {
                  value: convertFromIntToPrice(state.totalPrice),
                  breakdown: {
                    item_total: {
                      value: convertFromIntToPrice(state.totalPrice),
                      currency_code: "USD",
                    },
                  },
                },
                items: lineItems,
                shipping: {
                  options: [
                    {
                      id: "SHIP_123",
                      label: "Free Shipping",
                      type: "SHIPPING",
                      selected: true,
                      amount: {
                        value: "0.00",
                        currency_code: "USD",
                      },
                    },
                  ],
                },
              },
            ],
          })
        }}
        onApprove={(data, actions) => {
          return actions.order.capture().then(details => {
            const name = details.payer.name.given_name
            navigate("/success", { replace: true })
          })
        }}
      />
    </PayPalScriptProvider>
  )
}

export default PayPal

import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

const CartItemPreview = ({ product }) => {
  const renderView = sku => {
    switch (sku) {
      case "JW-FL22-SL01-SM-A01":
        return (
          <StaticImage
            src="../../images/ashur_pendant/insta-ashur-5.png"
            quality={95}
            formats={["AUTO", "WEBP"]}
            alt="Ashur pendant in size small"
            className="img-fluid rounded"
          />
        )
      case "JW-FL22-SL01-MD-A01":
        return (
          <StaticImage
            src="../../images/ashur_pendant/insta-ashur-4.png"
            quality={95}
            formats={["AUTO", "WEBP"]}
            alt="Ashur pendant in size medium"
            className="img-fluid rounded"
          />
        )
      case "JW-FL22-SL01-LG-A01":
        return (
          <StaticImage
            src="../../images/ashur_pendant/insta-ashur-2.png"
            quality={95}
            formats={["AUTO", "WEBP"]}
            alt="Ashur pendant in size large"
            className="img-fluid rounded"
          />
        )
      case "JW-SP23-SL01-UI-B01":
      case "JW-SP23-YG18-UI-B01":
        return (
          <StaticImage
            src="../../images/lamassu_pendant/image_0_0.jpg"
            quality={95}
            formats={["AUTO", "WEBP"]}
            alt="Lamassu pendant"
            className="img-fluid rounded"
          />
        )
      case "JW-SM23-SL01-UI-C01":
      case "JW-SM23-YG10-UI-C01":
      case "JW-SM23-YG14-UI-C01":
      case "JW-SM23-YG18-UI-C01":
        return (
          <StaticImage
            src="../../images/assyrian_flag_pendant/image_0_0.jpg"
            quality={95}
            formats={["AUTO", "WEBP"]}
            alt="Assyrian flag pendant"
            className="img-fluid rounded"
          />
        )
      case "JW-SM23-SL01-UI-C02":
      case "JW-SM23-YG10-UI-C02":
      case "JW-SM23-YG14-UI-C02":
      case "JW-SM23-YG18-UI-C02":
        return (
          <StaticImage
            src="../../images/rosette_pendant/image_0_0.jpg"
            quality={95}
            formats={["AUTO", "WEBP"]}
            alt="Rosette pendant"
            className="img-fluid rounded"
          />
        )
      case "JW-SM23-SL01-UI-C03":
      case "JW-SM23-YG10-UI-C03":
      case "JW-SM23-YG14-UI-C03":
      case "JW-SM23-YG18-UI-C03":
        return (
          <StaticImage
            src="../../images/tofl_pendant/image_0_0.jpg"
            quality={95}
            formats={["AUTO", "WEBP"]}
            alt="Tree of Life pendant"
            className="img-fluid rounded"
          />
        )
      case "JW-SP24-SL01-UI-D01":
      case "JW-SP24-SL01-UI-D02":
      case "JW-SP24-SL01-UI-D03":
      case "JW-SP24-SL01-UI-D04":
      case "JW-SP24-SL01-UI-D05":
      case "JW-SP24-SL01-UI-D06":
      case "JW-SP24-SL01-UI-D07":
      case "JW-SP24-SL01-UI-D08":
      case "JW-SP24-SL01-UI-D09":
      case "JW-SP24-SL01-UI-D10":
        return (
          <StaticImage
            src="../../images/ashur_lapis/image_0_0.jpg"
            quality={95}
            formats={["AUTO", "WEBP"]}
            alt="Ashur x Lapis Lazuli ring"
            className="img-fluid rounded"
          />
        )
      default:
        return "Not Found..."
    }
  }

  return renderView(product?.toUpperCase())
}

export default CartItemPreview

import React, { useContext } from "react"
import { Col, Container, Row, Spinner } from "react-bootstrap"
import Checkout from "../components/checkout"
import Paypal from "../components/paypal"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import { Context } from "../contexts/StateStore"
import CartItemPreview from "../components/CartItemPreview/CartItemPreview"

const CheckoutPage = () => {
  const [state] = useContext(Context)

  if (state?.cart?.length === 0) {
    return (
      <Layout>
        <Seo title="Checkout" />
        <Container className="container py-5 text-white text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
          <p className="h4 bank-font">Loading</p>
        </Container>
      </Layout>
    )
  }

  return (
    <Layout>
      <Seo title="Checkout" />
      <Container className="container py-5 text-white">
        <Row className="justify-content-between">
          <Col md="5">
            <ul className="list-unstyled">
              {state?.cart?.map((item, index) => (
                <li className="mb-3" key={index}>
                  <Row>
                    <Col md="2" className="col-2">
                      <CartItemPreview product={item.sku} />
                    </Col>
                    <Col md="8" className="col-8">
                      <p className="mb-0 gold-text">
                        {item.title} ({item.name})
                      </p>
                      <p className="mb-0 h6">
                        ${((item.price * item.qty) / 100).toFixed(2)}
                      </p>
                      <p className="mb-0 h6">Qty: {item.qty}</p>
                    </Col>
                    <Col md="12">
                      <hr className="w-100" />
                    </Col>
                  </Row>
                </li>
              ))}
            </ul>
          </Col>
          <Col md="6">
            <Row className="justify-content-end">
              <Col md="12">
                <p className="h5 d-flex align-items-center justify-content-between">
                  <span className="gold-text">Total</span>{" "}
                  <span>
                    <sup style={{ fontSize: "10px" }}>USD</sup> $
                    {(state?.totalPrice / 100).toFixed(2)}
                  </span>
                </p>
                <p className="h5 d-flex align-items-center justify-content-between">
                  <span className="gold-text">Tax</span> <span>$0.00</span>
                </p>
                <p className="h5 d-flex align-items-center justify-content-between">
                  <span className="gold-text">Shipping</span> <span>$0.00</span>
                </p>
                <hr className="w-100" />
              </Col>
              <Col md="12" />
              <Col md="6" className="pb-3">
                <Checkout />
              </Col>
              <Col md="6" className="pb-3">
                <Paypal />
              </Col>
              <Col md="12" className="mt-3">
                <ul
                  className="minimal-body ps-0 list-unstyled"
                  style={{ fontSize: 12, lineHeight: "1.2" }}
                >
                  <li className="mb-3">
                    * Taxes, Shipping and Credit Card fees are all handled by
                    us, but if you are outside of Canada you might incur duty
                    fees or taxes based on your country so please keep that in
                    mind.
                  </li>
                  <li className="mb-3">
                    * We currently don't accept any returns, therefore all sales
                    are final.
                  </li>
                  <li>
                    * If paying with PayPal account, ensure you have your
                    mailing address set and up to date as that's what we use for
                    shipping.
                  </li>
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default CheckoutPage
